@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;600&family=Inter:wght@400;500;600&family=Lato:wght@400;700;900&family=Montserrat:wght@400;500;600&family=Open+Sans:wght@400;600&family=Roboto:wght@400;500;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  height: 100%;
}

#root {
  overscroll-behavior-y: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0;
  overflow-y: scroll;
}

html {
  overflow: hidden;
}

/* overriding */
button {
  outline: none;
}

button:focus {
  outline: none;
}

/* scroll bar */
.custom-scroll-bar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: transparent;
}

.custom-scroll-bar::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

.custom-scroll-bar::-webkit-scrollbar-thumb {
  background-color: #657080;
  border-radius: 5px;
}

#customise-color-picker {
  width: 12px;
  height: 12px;
  padding: 0;
  margin: 0;
  border: 0px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}

#customise-color-picker::-webkit-color-swatch-wrapper {
  padding: 0;
}

#customise-color-picker::-webkit-color-swatch {
  border: none;
}

/* custom color toggle button */
input.custom-toggle[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}

label.custom-toggle-label {
  cursor: pointer;
  text-indent: -9999px;
  width: 28px;
  height: 14px;
  background: #cccccc;
  opacity: 1;
  border-radius: 7px;
  display: block;
  position: relative;
}

label.custom-toggle-label:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 40%;
  transform: translate(-70%, -50%);
  width: 20px;
  height: 20px;
  background: #8f8f8f;
  border-radius: 50%;
  transition: 0.3s;
}

input.custom-toggle:checked + label.custom-toggle-label {
  background: #193a6e;
}

input.custom-toggle:checked + label.custom-toggle-label:after {
  top: 50%;
  left: 0%;
  transform: translate(70%, -50%);
  background: #2f80ff;
}

/* modal */
#modal-root {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

#dropdown-root {
  position: absolute;
  z-index: 9999;
}

/* custom loader spinner */
.custom-loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #2f80ff;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 800ms linear infinite;
  animation: spin 800ms linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
